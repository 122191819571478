<template>
    <div class="content-wrapper">
        <div class="row ps-3 pe-3">
            <div class="col col-12 form-group">
                <label for="tb_tags">Tags</label>
                <dx-tag-box
                    automation_id="tb_tags"
                    ref="tb_tags"
                    class="form-control"
                    :data-source="filteredTags"
                    display-expr="name"
                    value-expr="id"
                    :search-enabled="true"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model="tagIDs"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import DxTagBox from "devextreme-vue/tag-box";
    import { mapGetters } from "vuex";

    export default {
        name: 'FileScanDocumentAssignTag',
        components: { DxTagBox },
        props: {
            originalTagIDs: {type: Array, required: false, default: () => []}
        },
        data() {
            return {
                tagIDs: []
            }
        },
        created() {
            this.tagIDs = this.originalTagIDs;       
        },
        computed:  {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
            ]),
            filteredTags() {
                const self = this;
                return _.filter(self.lookupHelpers.getAllLookupItems(self.lookupItems.DOCUMENT_TAGS), x => !x.inactive || _.includes(self.originalTagIDs, x.id));
            },
        }
    }
</script>
<style lang="scss" scoped>
.dx-list-select-all {
    display: none;
}
</style>